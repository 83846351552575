import React from 'react';
import Layout from 'components/layout';

import bgImage from 'images/services.png';
import ServicesTable from './table.js';

const ServicesPage = () => (
    <Layout>
        <div className="fluid-container page-container salon-container">
            <div className="container">
                <img src={bgImage} className="bg-image" />
                <div className="row">
                    <div className="col-sm-6 col-sm-offset-6 salon-content">
                        <h2 className="page-title">Salon Services</h2>
                        <div className="row prices-menu">
                            <div className="prices-scroller">
                                <ServicesTable />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default ServicesPage;
