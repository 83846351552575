import React from 'react';

import { data } from './data.json';

const Table = () => (
    <table className="service-table">
        {data.map(({ bodyClasses, title, services, note }) => (
            <tbody className={bodyClasses} key={title}>
                <tr>
                    <td className="service-section" colSpan="2">
                        <h3>{title}</h3>
                    </td>
                </tr>
                {services.map(serv => {
                    const opts = !serv.price ? { colSpan: 2 } : {};
                    return (
                        <tr key={serv.service}>
                            <td className="service" {...opts}>
                                {serv.service}
                            </td>
                            {serv.price && (
                                <td className="price">{serv.price}</td>
                            )}
                        </tr>
                    );
                })}
                {note && (
                    <tr>
                        <td colSpan="2">
                            <em>{note}</em>
                        </td>
                    </tr>
                )}
            </tbody>
        ))}
    </table>
);

export default Table;
